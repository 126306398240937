import { ToolBarCodeExportAll } from '@/frame/share/table-panel'

const time = (time: number[]) => {
  return time.slice(0, 3).map(item => item.toString().padStart(2, '0')).join('/') + ' ' +
    time.slice(3, 6).map(item => item.toString().padStart(2, '0')).join(':')
}

/// 表格基本配置
const options = {}
/// 导出列定义
const columns = [
  {
    field: 'requestTime',
    title: '请求时间',
    formatter: ({ row }: Record<string, any>) => {
      if (row.requestTime) {
        return time(row.requestTime)
      }
    },
    width: 170
  },
  { field: 'methodType', title: '方法类型', width: 80 },
  { field: 'requestContextType', title: '请求类型', width: 150 },
  { field: 'requestUrl', title: '请求地址', width: 170 },
  { field: 'duration', title: '请求用时(ms)', width: 80 },
  { field: 'responseCode', title: '应答码', width: 80 },
  { field: 'targetServer', title: '目标服务器', width: 150 },
  {
    field: 'error',
    title: '是否出错',
    formatter: ({ row }: Record<string, any>) => {
      return row.error ? '是' : '否'
    },
    width: 80
  },
  {
    field: 'responseTime',
    title: '应答时间',
    formatter: ({ row }: Record<string, any>) => {
      if (row.responseTime) {
        return time(row.responseTime)
      }
    },
    width: 170
  },
  { field: 'urlParamers', title: '请求参数', width: 170 },
  { field: 'requestBody', title: '请求体内容', width: 170 },
  { field: 'errorMessage', title: '错误原因', width: 170 }
]

/// 导出工具栏定义
const toolbarConfig = {
  buttons: [
    { code: 'insert', name: '新增', icon: 'md-add' },
    { code: 'delete', name: '直接删除', icon: 'fa fa-trash-o', status: 'error' },
    { code: ToolBarCodeExportAll, name: '全量导出', icon: 'vxe-icon--download', status: 'success' }
  ],
  refresh: true,
  export: true,
  print: true,
  zoom: true,
  custom: true,
  exportall: true
}

/**
 * 表格的打印配置
 */
const printConfig = {
  sheetName: '打印表格'
}

/**
 * 表格的导出配置
 */
const tableExport = {
  // 默认选中类型
  type: 'xlsx',
  // 自定义类型
  types: ['xlsx', 'csv', 'html', 'xml', 'txt']
}

/// 导出配置
export default {
  options: options,
  columns: columns,
  toolbar: toolbarConfig,
  printConfig: printConfig,
  tableExport: tableExport
}
