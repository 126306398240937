import { ApiResult, DataApiResult, PageDataApiResult, PagerQueryBean, requestRawResponse } from '@/libs/http-request'
import { Method } from 'axios'
import { AppInstaceMangerRequestData } from './app-instance-manger'
import { DeployRequestApi } from './deploy-request'

/**
 * API网关
 */
export interface ApiLogConfig {
  /** id */
  id: string;
  /** 配置名称 */
  name: string;
  /** 匹配url地址 */
  antMatcherUrl: string;
  /** 是否生成请求日志 */
  logRequest: boolean;
  /** 是否生成请求体日志 */
  logBody: boolean;
  /** 记录修改时间 */
  updateTime: string;
  /// 删除标识
  deleted: boolean;
}

/**
 * 团队服务请求api接口
 */
class ApiLogConfigApi {
  baseUrlPath='/api/maintain/gateway-instance/'

  /**
   * 通过实例ip获取数据
   * @param query
   * @param ip
   * @returns
   */
  public queryByIp (query: PagerQueryBean, ip: string, port: string, instanceId: number): Promise<PageDataApiResult<ApiLogConfig>> {
    const url = `${location.protocol}//${ip}:${port}/apigateway/apilogconfig/query`
    return this.encodeUrlRequest(instanceId, url, 'POST', query)
  }

  /**
    * 删除id列表指定的数据
    * @param ids 记录主键列表
    */
  public deleteItemByIds (ip: string, port: string, ids: string[], instanceId: number): Promise<ApiResult> {
    const url = `${location.protocol}//${ip}:${port}/apigateway/apilogconfig/`
    return this.encodeUrlRequest(instanceId, url, 'DELETE', ids)
  }

  /**
  * 新增数据
  * @param item
  * @param ip
  * @param port
  * @param instanceId
  * @returns
  */
  public insertItem (item: ApiLogConfig, ip: string, port: string, instanceId: number): Promise<PageDataApiResult<ApiLogConfig>> {
    const url = `${location.protocol}//${ip}:${port}/apigateway/apilogconfig/`
    return this.encodeUrlRequest(instanceId, url, 'PUT', item)
  }

  /**
   * 修改数据
   * @param item
   * @param ip
   * @param port
   * @param instanceId
   * @returns
   */
  public updateItem (item: ApiLogConfig, ip: string, port: string, instanceId: number): Promise<PageDataApiResult<ApiLogConfig>> {
    const url = `${location.protocol}//${ip}:${port}/apigateway/apilogconfig/`
    return this.encodeUrlRequest(instanceId, url, 'POST', item)
  }

  /** gateway请求 */
  public encodeUrlRequest (instanceId: number, url: string, methods: Method, data?: any) {
    return new Promise<any>((resolve, reject) => {
      requestRawResponse<DataApiResult<AppInstaceMangerRequestData<any>>>({
        url: `${this.baseUrlPath}encodeRequestUrl/${instanceId}`,
        data: url,
        method: 'POST',
        headers: {
          'Content-Type': 'application/xml'
        }
      }).then((res) => {
        requestRawResponse({
          url: url,
          method: methods,
          headers: {
            'Content-Type': 'application/json',
            Authorization: res.headers[DeployRequestApi.author_gateway_aukey]
          },
          data: data,
          timeout: 5000
        }, false).then((re) => {
          resolve(re.data)
        }).catch((err) => {
          reject(err)
        })
      })
    })
  }
}

export default new ApiLogConfigApi()
