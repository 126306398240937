import { ApiResult, DataApiResult, PageDataApiResult, PagerQueryBean, requestRawResponse } from '@/libs/http-request'
import { DeployRequestApi } from './deploy-request'
import { AppInstaceMangerRequestData } from './app-instance-manger'
import axios, { Method } from 'axios'

/**
 * API网关
 */
export interface ApiGateway {

  id?: string;

  // 网关规则名称
  name?: string;

  // 网关规则说明
  memo?: string;

  // 是否启用规则
  enable?: boolean;

  /// 最近启用时间
  lastEffectiveTime?: string;

  // 实例ip
  ip?: string;

  // 网关组名称
  apigatewayGroupName?: string;

  // 创建时间
  createTime?: string;

  // 修改时间
  modifyTime?: string;

}

/**
 * 团队服务请求api接口
 */
class ApiGatewayApi {
  baseUrlPath='/api/maintain/gateway-instance/'

  /**
    * 通过查询bean查询数据
    * @param query 查询bean数据对象
    */
  public changeEnableByIdByIp (ip: string, id: string, enable: boolean, port: string, instanceId: number): Promise<ApiResult> {
    const url = `${location.protocol}//${ip}:${port}/apigateway/apiGateway/${id}/${enable}`
    return this.encodeUrlRequest(instanceId, url, 'GET')
  }

  /**
    * 取得id对应的对象的值
    * @param id 要删除记录的主键id
    */
  public getItemByIdByIp (ip: string, id: string, port: string, instanceId: number): Promise<DataApiResult<ApiGateway>> {
    const url = `${location.protocol}//${ip}:${port}/apigateway/apiGateway/${id}`
    return this.encodeUrlRequest(instanceId, url, 'GET')
  }

  /**
    * 删除id列表指定的数据
    * @param ids 记录主键列表
    */
  public deleteItemByIds (ip: string, port: string, ids: string[], instanceId: number): Promise<ApiResult> {
    const url = `${location.protocol}//${ip}:${port}/apigateway/apiGateway/`
    return this.encodeUrlRequest(instanceId, url, 'DELETE', ids)
  }

  /**
    * 通过查询路由名查询数据
    * @param name
    */
  public getItemsByNameByIp (ip: string, name: string, port: string, instancdId: number): Promise<DataApiResult<ApiGateway[]>> {
    const url = `${location.protocol}//${ip}:${port}/apigateway/apiGateway/byName/${encodeURIComponent(name)}`
    return this.encodeUrlRequest(instancdId, url, 'GET', undefined)
  }

  /**
    * 刷新路由规则
    * @param name
    */
  public reoloadRouteRule (ip: string, port: string, instancdId: number): Promise<DataApiResult<ApiGateway[]>> {
    const url = `${location.protocol}//${ip}:${port}/apigateway/apiGateway/reload`
    return this.encodeUrlRequest(instancdId, url, 'GET', undefined)
  }

  /** 获取导出数据 */
  public exportGatewayData (ip: string, port: string, instanceId: number) {
    const url = `${location.protocol}//${ip}:${port}/apigateway/apiGateway/exportAllData`
    return this.encodeUrlRequest(instanceId, url, 'GET', undefined)
  }

  /** 获取导出数据 */
  public exportDataByGatewayId (ip: string, port: string, instanceId: number, gatewayId: string) {
    const url = `${location.protocol}//${ip}:${port}/apigateway/apiGateway/exportData/${gatewayId}`
    return this.encodeUrlRequest(instanceId, url, 'GET', undefined)
  }

  /** 导入数据 */
  public importGatewayData (ip: string, port: string, instanceId: number, data: any) {
    const url = `${location.protocol}//${ip}:${port}/apigateway/apiGateway/importData`
    return this.encodeUrlRequest(instanceId, url, 'POST', data)
  }

  /** gateway请求 */
  public encodeUrlRequest (instanceId: number, url: string, methods: Method, data?: any) {
    return new Promise<any>((resolve, reject) => {
      requestRawResponse<DataApiResult<AppInstaceMangerRequestData<any>>>({
        url: `${this.baseUrlPath}encodeRequestUrl/${instanceId}`,
        data: url,
        method: 'POST',
        headers: {
          'Content-Type': 'application/xml'
        }
      }).then((res) => {
        requestRawResponse({
          url: url,
          method: methods,
          headers: {
            'Content-Type': 'application/json',
            Authorization: res.headers[DeployRequestApi.author_gateway_aukey]
          },
          data: data,
          timeout: 5000
        }, false).then((re) => {
          resolve(re.data)
        }).catch((err) => {
          reject(err)
        })
      })
    })
  }

  /** 初始化网关 */
  public initGateWay (teamId: number, instanceId: number, ip: string, port: string) {
    return new Promise<any>((resolve, reject) => {
      requestRawResponse<DataApiResult<string>>({
        url: `${this.baseUrlPath}/initGatewayService`,
        method: 'PUT',
        data: {
          teamId: teamId,
          instanceId: instanceId
        }
      }).then((res) => {
        if (res.data.code === 0) {
          axios.request({
            url: `${location.protocol}//${ip}:${port}/files/security`,
            method: 'POST',
            headers: {
              'Content-Type': 'application/xml'
            },
            data: res.data.data,
            timeout: 5000
          }).then((re) => {
            resolve(re.data)
          }).catch((err) => {
            reject(err)
          })
        } else {
          reject(res.data.message)
        }
      })
    })
  }

  /**
   * 网关初始化完成
   * @param instanceId
   */
  public finishInit (instanceId: number) {
    return requestRawResponse<DataApiResult<string>>({
      url: `${this.baseUrlPath}/initFinish/${instanceId}`,
      method: 'GET'
    })
  }

  /**
   * 通过实例ip获取数据
   * @param query
   * @param ip
   * @returns
   */
  public queryByIp (query: PagerQueryBean, ip: string, port: string, instanceId: number): Promise<PageDataApiResult<ApiGateway>> {
    const url = `${location.protocol}//${ip}:${port}/apigateway/apiGateway/query`
    return this.encodeUrlRequest(instanceId, url, 'POST', query)
  }

  /**
    * 新增指定的数据
    * @param data
    */
  public insertItemByIp (ip: string, data: ApiGateway, port: string, instanceId: number): Promise<DataApiResult<ApiGateway>> {
    (data as any).id = undefined
    const url = `${location.protocol}//${ip}:${port}/apigateway/apiGateway/`
    return this.encodeUrlRequest(instanceId, url, 'PUT', data)
  }

  public updateItemByIp (ip: string, item: ApiGateway, port: string, instanceId: number) {
    const url = `${location.protocol}//${ip}:${port}/apigateway/apiGateway/`
    return this.encodeUrlRequest(instanceId, url, 'POST', item)
  }
}

export default new ApiGatewayApi()
