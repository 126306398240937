
import { Component, Prop, Watch } from 'vue-property-decorator'
import tableConfig from './log-table-config'
import { PropType } from 'vue/types/v3-component-props'
import { PageDataApiResult, PageListData, PagerQueryBean } from '@/libs/http-request'
import BaseTablePanel from '@/frame/share/table-panel'
import _ from 'lodash'
import { getterUserInfo } from '@/store'
import ApiLogApi, { ApiLog } from '@/api/maintain/api-log'
import { PageInfo } from '@/libs/api-request'
import { AppInstance } from '@/api/maintain/app-instance'
import TablePanel from '@/frame/share/table-panel.vue'

@Component({
  name: 'Log',
  components: { TablePanel }
})
export default class Log extends BaseTablePanel<ApiLog> {
  @Prop({
    type: String,
    required: true
  })
  ip!: string

  @Prop({
    type: String,
    required: true
  })
  port!: string

  @Prop({
    type: String,
    required: true
  })
  apigatewayGroupName!: string

  @Prop({
    type: Object as PropType<AppInstance>,
    required: true
  })
  curInstance!: AppInstance

  @Prop({
    type: Object as PropType<{ page: 1; pageSize: 10; sorts: []; requestUrl: ''; methodType: ''; startTime: ''; endTime: ''; duration: null; error: null; responseCode: null }>,
    required: true
  })
  logQuery!: { page: 1; pageSize: 10; sorts: ['requestTime desc']; requestUrl: ''; methodType: ''; startTime: ''; endTime: ''; duration: null; error: null; responseCode: null }

  // 当前状态
  loading = false;
  // 表格定义
  tableConfig = tableConfig
  /// 查询数据
  queryData: PagerQueryBean = _.cloneDeep(this.logQuery)
  selectDataItems: Array<ApiLog> = [];

  @Watch('logQuery', { immediate: true, deep: true })
  onLogQueryChange () {
    this.queryData = _.cloneDeep(this.logQuery)
    this.queryData.duration = parseFloat(this.logQuery.duration || '') * 1000
  }

  /**
   * 页面数据
   */
   pageData: PageListData<ApiLog> = {
     items: [],
     total: 0,
     pageSize: 10
   };

   /**
   * 当前用户的项目编辑权限
   */
   get canEdit () {
     const user = getterUserInfo()
     return user.canOperateApiGateway && this.curInstance.initFinished
   }

   /**
   * 页面数据拉取
   */
   doPullDataList (pageInfo?: PageInfo) {
     if (!this.ip) this.$Notice.warning({ title: '警告', desc: '请先选择实例' })
     else if (!this.curInstance.initFinished) {
       this.$Message.error('该网关未初始化，请先初始化！')
       this.pageData = {
         items: [],
         total: 0,
         pageSize: 10
       }
     } else {
       pageInfo = pageInfo || { curPage: 1, pageSize: 10 }

       this.pullDataList(pageInfo).then((response) => {
         this.pageData = response
         this.pageData.items = this.pageData.items.map((item) => { return { ...item, ip: this.ip } })

         this.$Notice.success({ title: '成功', desc: '查询成功' })
       })
     }
   }

   /**
   * 工具栏按钮事件
   */
   doToolBarButtonAction (code: string) {
     this.toolBarButtonAction(code, (this.$refs.tablePanel as any).getTableObject())
   }

   /**
   * 从服务器拉取数据
   */
   onPullDataListFromServer (
     pagerQueryBean: PagerQueryBean
   ): Promise<PageDataApiResult<ApiLog>> {
     return ApiLogApi.queryByIp(pagerQueryBean, this.ip, this.port, this.curInstance.id!)
   }
}
