
import { Component, Prop, Watch } from 'vue-property-decorator'
import tableConfig from './log-config-table-config'
import { PropType } from 'vue/types/v3-component-props'
import { PageDataApiResult, PageListData, PagerQueryBean } from '@/libs/http-request'
import BaseTablePanel from '@/frame/share/table-panel'
import _ from 'lodash'
import { getterUserInfo } from '@/store'
import ApiLogConfigApi, { ApiLogConfig } from '@/api/maintain/api-log-config'
import { PageInfo } from '@/libs/api-request'
import { AppInstance } from '@/api/maintain/app-instance'
import TablePanel from '@/frame/share/table-panel.vue'
import showLogConfigModal from './log-config-modal'

@Component({
  name: 'LogConfig',
  components: { TablePanel }
})
export default class LogConfig extends BaseTablePanel<ApiLogConfig> {
  @Prop({
    type: String,
    required: true
  })
  ip!: string

  @Prop({
    type: String,
    required: true
  })
  port!: string

  @Prop({
    type: String,
    required: true
  })
  apigatewayGroupName!: string

  @Prop({
    type: Object as PropType<AppInstance>,
    required: true
  })
  curInstance!: AppInstance

  @Prop({
    type: Object as PropType<{ page: 1; pageSize: 10; sorts: []; name: ''; antMatcherUrl: '' }>,
    required: true
  })
  logConfigQuery!: { page: 1; pageSize: 10; sorts: []; name: ''; antMatcherUrl: '' }

  // 当前状态
  loading = false;
  // 表格定义
  tableConfig = tableConfig
  /// 查询数据
  queryData: PagerQueryBean = _.cloneDeep(this.logConfigQuery)
  selectDataItems: Array<ApiLogConfig> = []

  @Watch('logConfigQuery', { immediate: true, deep: true })
  onLogQueryChange () {
    this.queryData = _.cloneDeep(this.logConfigQuery)
  }

  /**
   * 页面数据
   */
   pageData: PageListData<ApiLogConfig> = {
     items: [],
     total: 0,
     pageSize: 10
   };

   /**
   * 当前用户的项目编辑权限
   */
   get canEdit () {
     const user = getterUserInfo()
     return user.canOperateApiGateway && this.curInstance.initFinished
   }

   showLogConfigModal (row?: ApiLogConfig) {
     showLogConfigModal(row).then((res) => {
       if (row) {
         ApiLogConfigApi.updateItem(res, this.ip, this.port, this.curInstance.id!)
           .then(() => {
             this.$Message.success('修改成功')
             this.doPullDataList()
           })
           .catch((err) => {
             this.$Message.error('修改失败: ' + err)
           })
       } else {
         ApiLogConfigApi.insertItem(res, this.ip, this.port, this.curInstance.id!)
           .then(() => {
             this.$Message.success('添加成功')
             this.doPullDataList()
           })
           .catch((err) => {
             this.$Message.error('添加失败: ' + err)
           })
       }
     })
   }

   /**
   * 页面数据拉取
   */
   doPullDataList (pageInfo?: PageInfo) {
     if (!this.ip) this.$Notice.warning({ title: '警告', desc: '请先选择实例' })
     else if (!this.curInstance.initFinished) {
       this.$Message.error('该网关未初始化，请先初始化！')
       this.pageData = {
         items: [],
         total: 0,
         pageSize: 10
       }
     } else {
       pageInfo = pageInfo || { curPage: 1, pageSize: 10 }

       this.pullDataList(pageInfo).then((response) => {
         this.pageData = response
         this.pageData.items = this.pageData.items.map((item) => { return { ...item, ip: this.ip } })

         this.$Notice.success({ title: '成功', desc: '查询成功' })
       })
     }
   }

   /**
   * 工具栏按钮事件
   */
   doToolBarButtonAction (code: string) {
     this.toolBarButtonAction(code, (this.$refs.tablePanel as any).getTableObject())
   }

   /**
   * 从服务器拉取数据
   */
   onPullDataListFromServer (
     pagerQueryBean: PagerQueryBean
   ): Promise<PageDataApiResult<ApiLogConfig>> {
     return ApiLogConfigApi.queryByIp(pagerQueryBean, this.ip, this.port, this.curInstance.id!)
   }

   /**
   * 全部选中 或 单条选中
   * 列表选中的行
   */
   onSelectionChange (rows: Array<ApiLogConfig>) {
     this.selectDataItems = rows
   }

   /**
   * 批量删除
   */
   deleteBatchRows () {
     if (this.selectDataItems.length === 0) {
       this.$Notice.warning({
         title: '提示',
         desc: '请选择您要删除记录'
       })
       return
     }
     this.$Modal.confirm({
       title: '提示',
       content: '已选中' + this.selectDataItems.length + '条数据,确定要删除吗？',
       onOk: () => {
         const ids: Array<string> = this.selectDataItems.map((item) => {
           return item!.id!
         })
         this.deleteData(ids)
       }
     })
   }

   /// 表格数据删除
   deleteRow (row: ApiLogConfig) {
     this.$Modal.confirm({
       title: '提示',
       content: '是否确认删除当前配置？',
       onOk: () => {
         this.deleteData([row.id!])
       }
     })
   }

   /// 删除数据方法
   deleteData (ids: Array<string>) {
     ApiLogConfigApi
       .deleteItemByIds(this.ip, this.port, ids, this.curInstance.id!)
       .then(() => {
         this.$Notice.success({ title: '提示', desc: '删除成功' })
         this.doPullDataList()
       })
       .catch((err: Error) => {
         this.$Notice.error({
           title: '删除记录失败',
           desc: err.message
         })
       })
       .finally(() => this.finishLoading())
   }
}
