
import { Component, Prop, Watch } from 'vue-property-decorator'
import tableConfig from './gateway-table-config'
import { PropType } from 'vue/types/v3-component-props'
import { PageDataApiResult, PageListData, PagerQueryBean } from '@/libs/http-request'
import BaseTablePanel from '@/frame/share/table-panel'
import _ from 'lodash'
import { getterUserInfo } from '@/store'
import apiGateway, { ApiGateway } from '@/api/maintain/api-gateway'
import { PageInfo } from '@/libs/api-request'
import { AppInstance } from '@/api/maintain/app-instance'
import TablePanel from '@/frame/share/table-panel.vue'
import FileSaver from 'file-saver'
import { ApiGatewayRoute } from '@/api/maintain/api-gateway-route'
import { ApiGatewayRouteRule } from '@/api/maintain/api-gateway-route-rule'
import { ApiGatewayRouteFilter } from '@/api/maintain/api-gateway-route-filter'
/** 导出网关数据 */
interface GatewayExportData {
  gateways: ApiGateway[];

  apiGatewayRoutes: ApiGatewayRoute[];

  apiGatewayRouteRules: ApiGatewayRouteRule[];

  apiGatewayRouteFilters: ApiGatewayRouteFilter[];
}

@Component({
  name: 'Gateway',
  components: { TablePanel }
})
export default class Gateway extends BaseTablePanel<ApiGateway> {
  @Prop({
    type: String,
    required: true
  })
  ip!: string;

  @Prop({
    type: String,
    required: true
  })
  port!: string;

  @Prop({
    type: String,
    required: true
  })
  apigatewayGroupName!: string;

  @Prop({
    type: Object as PropType<AppInstance>,
    required: true
  })
  curInstance!: AppInstance;

  @Prop({
    type: Object as PropType<{
      page: 1;
      pageSize: 10;
      sorts: [];
      apigatewayGroupName: '';
      name: '';
    }>,
    required: true
  })
  gatewayQuery!: { page: 1; pageSize: 10; sorts: []; apigatewayGroupName: ''; name: '' };

  // 当前状态
  loading = false;
  // 表格定义
  tableConfig = tableConfig;
  /// 查询数据
  queryData: PagerQueryBean = _.cloneDeep(this.gatewayQuery);
  selectDataItems: Array<ApiGateway> = [];

  @Watch('gatewayQuery', { immediate: true, deep: true })
  onLogQueryChange () {
    this.queryData = _.cloneDeep(this.gatewayQuery)
  }

  /**
   * 页面数据
   */
  pageData: PageListData<ApiGateway> = {
    items: [],
    total: 0,
    pageSize: 10
  };

  /**
   * 当前用户的项目编辑权限
   */
  get canEdit () {
    const user = getterUserInfo()
    return user.canOperateApiGateway && this.curInstance.initFinished
  }

  /// 重新启用
  restart (id: string) {
    this.$Modal.confirm({
      title: '提示',
      content: '确认要重新启用吗',
      onOk: () => {
        apiGateway
          .changeEnableByIdByIp(this.ip, id, true, this.port, this.curInstance.id!)
          .then(() => {
            this.$Notice.success({
              title: '保存成功'
            })
          })
          .catch((err) => {
            this.$Notice.error({
              title: '保存失败',
              desc: err
            })
          })
          .finally(() => {
            this.loading = false
            this.doPullDataList()
          })
      }
    })
  }

  /**
   * 页面数据拉取
   */
  doPullDataList (pageInfo?: PageInfo) {
    if (!this.ip) this.$Notice.warning({ title: '警告', desc: '请先选择实例' })
    else if (!this.curInstance.initFinished) {
      this.$Message.error('该网关未初始化，请先初始化！')
      this.pageData = {
        items: [],
        total: 0,
        pageSize: 10
      }
    } else {
      pageInfo = pageInfo || { curPage: 1, pageSize: 10 }

      this.pullDataList(pageInfo).then((response) => {
        this.pageData = response
        this.pageData.items = this.pageData.items.map((item) => {
          return { ...item, ip: this.ip }
        })

        this.$Notice.success({ title: '成功', desc: '查询成功' })
      })
    }
  }

  /** 导出规则 */
  exportApiGateway () {
    apiGateway.exportGatewayData(this.ip, this.port, this.curInstance.id!).then((res) => {
      const blob = new Blob([JSON.stringify(res.data)], {
        type: 'text/plain;charset=utf-8'
      })
      FileSaver.saveAs(blob, 'test.json')
    })
  }

  /** 导入数据 */
  importApiGateway (file: File) {
    const reader = new FileReader()
    reader.readAsText(file)
    reader.onload = (e) => {
      if (e.target?.result) {
        const data = JSON.parse(e.target.result as string)
        apiGateway.importGatewayData(this.ip, this.port, this.curInstance.id!, data).then(() => {
          this.doPullDataList()
        })
      }
    }
    return false
  }

  /**
   * 工具栏按钮事件
   */
  doToolBarButtonAction (code: string) {
    this.toolBarButtonAction(code, (this.$refs.tablePanel as any).getTableObject())
  }

  /**
   * 从服务器拉取数据
   */
  onPullDataListFromServer (
    pagerQueryBean: PagerQueryBean
  ): Promise<PageDataApiResult<ApiGateway>> {
    return apiGateway.queryByIp(pagerQueryBean, this.ip, this.port, this.curInstance.id!)
  }

  /**
   * 全部选中 或 单条选中
   * 列表选中的行
   */
  onSelectionChange (rows: Array<ApiGateway>) {
    this.selectDataItems = rows
  }

  /**
   * 批量删除
   */
  deleteBatchRows () {
    if (this.selectDataItems.length === 0) {
      this.$Notice.warning({
        title: '提示',
        desc: '请选择您要删除记录'
      })
      return
    }
    this.$Modal.confirm({
      title: '提示',
      content: '已选中' + this.selectDataItems.length + '条数据,确定要删除吗？',
      onOk: () => {
        const ids: Array<string> = this.selectDataItems.map((item) => {
          return item!.id!
        })
        this.deleteData(ids)
      }
    })
  }

  /// 表格数据删除
  deleteRow (row: ApiGateway) {
    this.$Modal.confirm({
      title: '提示',
      content: row.enable
        ? '当前网关规则正在启用，删除后，网关规则将会失效，是否确认删除？'
        : '是否确认删除当前网关规则？',
      onOk: () => {
        this.deleteData([row.id!])
      }
    })
  }

  /// 删除数据方法
  deleteData (ids: Array<string>) {
    apiGateway
      .deleteItemByIds(this.ip, this.port, ids, this.curInstance.id!)
      .then(() => {
        this.$Notice.success({ title: '提示', desc: '删除成功' })
        this.doPullDataList()
      })
      .catch((err: Error) => {
        this.$Notice.error({
          title: '删除记录失败',
          desc: err.message
        })
      })
      .finally(() => this.finishLoading())
  }

  exportData (row: ApiGateway) {
    apiGateway.exportDataByGatewayId(this.ip, this.port, this.curInstance.id!, row.id!).then((res) => {
      const blob = new Blob([JSON.stringify(res.data)], {
        type: 'text/plain;charset=utf-8'
      })
      FileSaver.saveAs(blob, 'test.json')
    })
  }

  // 改变规则状态
  toggleEnable (row: ApiGateway) {
    this.loading = true
    if (row.id && row.enable !== undefined) {
      apiGateway
        .changeEnableByIdByIp(
          this.ip,
          row.id,
          row.enable,
          this.port,
          this.curInstance.id!
        )
        .then(() => {
          this.$Notice.success({
            title: '保存成功'
          })
        })
        .catch((err) => {
          this.$Notice.error({
            title: '保存失败',
            desc: err
          })
        })
        .finally(() => {
          this.loading = false
          this.doPullDataList()
        })
    } else {
      this.loading = false
    }
  }
}
