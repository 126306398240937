import { ToolBarCodeExportAll } from '@/frame/share/table-panel'

/// 表格基本配置
const options = {}
/// 导出列定义
const columns = [
  { field: 'name', title: '配置名称' },
  { field: 'antMatcherUrl', title: '匹配url地址' },
  {
    field: 'logRequest',
    title: '是否生成请求日志',
    formatter: ({ row }: Record<string, any>) => {
      return row.logRequest ? '是' : '否'
    }
  },
  {
    field: 'logBody',
    title: '是否生成请求体日志',
    formatter: ({ row }: Record<string, any>) => {
      return row.logBody ? '是' : '否'
    }
  },
  { width: 60, slot: 'col_action' }
]

/// 导出工具栏定义
const toolbarConfig = {
  buttons: [
    { code: 'insert', name: '新增', icon: 'md-add' },
    { code: 'delete', name: '直接删除', icon: 'fa fa-trash-o', status: 'error' },
    { code: ToolBarCodeExportAll, name: '全量导出', icon: 'vxe-icon--download', status: 'success' }
  ],
  refresh: true,
  export: true,
  print: true,
  zoom: true,
  custom: true,
  exportall: true
}

/**
 * 表格的打印配置
 */
const printConfig = {
  sheetName: '打印表格'
}

/**
 * 表格的导出配置
 */
const tableExport = {
  // 默认选中类型
  type: 'xlsx',
  // 自定义类型
  types: ['xlsx', 'csv', 'html', 'xml', 'txt']
}

/// 导出配置
export default {
  options: options,
  columns: columns,
  toolbar: toolbarConfig,
  printConfig: printConfig,
  tableExport: tableExport
}
