import { DataApiResult, PageDataApiResult, PagerQueryBean, requestRawResponse } from '@/libs/http-request'
import { AppInstaceMangerRequestData } from './app-instance-manger'
import { Method } from 'axios'
import { DeployRequestApi } from './deploy-request'
/**
 * API网关
 */
export interface ApiLog {
  /** id */
  id: string;
  /** 请求时间 */
  requestTime: string;
  /** 应答时间 */
  responseTime: string;
  /** 请求用时 */
  duration: string;
  /** 应答码 */
  responseCode: number;
  /** 目标服务器 */
  targetServer: string;
  /**  */
  targetAddress: string;
  /** 生效的routeId */
  routeId: string;
  /** 请求类型 */
  requestContextType: string;
  /** 方法类型 */
  methodType: string;
  /** 是否出错了 */
  error: boolean;
  /** 错误原因 */
  errorMessage?: string;
  /** 请求地址 */
  requestUrl: string;
  /** 请求参数 */
  urlParamers: string;
  /** 请求体内容 */
  requestBody: string;
}

/**
 * 团队服务请求api接口
 */
class ApiLogApi {
  baseUrlPath='/api/maintain/gateway-instance/'

  /**
   * 通过实例ip获取数据
   * @param query
   * @param ip
   * @returns
   */
  public queryByIp (query: PagerQueryBean, ip: string, port: string, instanceId: number): Promise<PageDataApiResult<ApiLog>> {
    const url = `${location.protocol}//${ip}:${port}/apigateway/apilog/query`
    return this.encodeUrlRequest(instanceId, url, 'POST', query)
  }

  /** gateway请求 */
  public encodeUrlRequest (instanceId: number, url: string, methods: Method, data?: any) {
    return new Promise<any>((resolve, reject) => {
      requestRawResponse<DataApiResult<AppInstaceMangerRequestData<any>>>({
        url: `${this.baseUrlPath}encodeRequestUrl/${instanceId}`,
        data: url,
        method: 'POST',
        headers: {
          'Content-Type': 'application/xml'
        }
      }).then((res) => {
        requestRawResponse({
          url: url,
          method: methods,
          headers: {
            'Content-Type': 'application/json',
            Authorization: res.headers[DeployRequestApi.author_gateway_aukey]
          },
          data: data,
          timeout: 5000
        }, false).then((re) => {
          resolve(re.data)
        }).catch((err) => {
          reject(err)
        })
      })
    })
  }
}

export default new ApiLogApi()
