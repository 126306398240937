import { showModal } from '@/libs/modal-helper'
import modal from './log-config-modal.vue'
import { ApiLogConfig } from '@/api/maintain/api-log-config'

/**
 * 显示应用配置模态框
 */
export default function showLogConfigModal (row?: ApiLogConfig): Promise<ApiLogConfig> {
  return new Promise<ApiLogConfig>((resolve, reject) => {
    showModal<ApiLogConfig>(modal, {
      props: {
        data: row
      }
    }, true, (res: ApiLogConfig) => {
      resolve(res)
      return new Promise<void>((resolve, reject) => resolve())
    }, () => {
      reject(new Error('用户取消'))
    })
  })
}
