
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { BaseModal } from '@/libs/modal-helper'
import { ApiLogConfig } from '@/api/maintain/api-log-config'
import { Form } from 'view-design'
import { PropType } from 'vue'

@Component({
  name: 'LogConfigModal'
})
export default class LogConfigModal extends Vue implements BaseModal {
  @Prop({
    type: Object as PropType<ApiLogConfig>,
    default: {}
  })
  data!: ApiLogConfig

  @Watch('data', { immediate: true })
  onDataChange (data: ApiLogConfig) {
    if (data.id) {
      this.formData = this.data
    } else {
      this.formData = {
        name: '',
        antMatcherUrl: '',
        logRequest: false,
        logBody: false
      } as ApiLogConfig
    }
  }

  // 显示状态
  visiable=false
  rules = {
    name: [{ required: true, message: '请输入配置名称' }],
    antMatcherUrl: [{ required: true, message: '请输入匹配url地址' }]
  }

  /**
   * 表单数据
   */
  formData = {
    name: '',
    antMatcherUrl: '',
    logRequest: false,
    logBody: false
  } as ApiLogConfig

  show (): void {
    // 显示状态
    this.visiable = true
  }

  close (): void {
    this.visiable = false
  }

  doSave (): void {
    const form = this.$refs.form as Form
    form.validate((valid) => {
      if (!valid) {
        this.$Message.error('请选择一个组织')
      } else {
        this.$emit('onOk', this.formData)
      }
    })
  }

  doCancel (): void {
    this.$emit('onCancel')
  }
}
