
import { Component, Vue, Watch } from 'vue-property-decorator'
import ExpendSearchPanel from '@/frame/share/expend-search-panel.vue'
import { PagerQueryBean } from '@/libs/http-request'
import _ from 'lodash'
import apiGateway from '@/api/maintain/api-gateway'
import AppInstanceApi, { AppInstance } from '@/api/maintain/app-instance'
import AppInstanceConfigApi from '@/api/maintain/app-instance-config'
import { getterUserInfo, updateGatewayInstance, getterGatewayInstance } from '@/store'
import { BaseConfigType } from '@/api/maintain/base-config'
import Gateway from './gateway.vue'
import Log from './log.vue'
import LogConfig from './log-config.vue'
import moment from 'moment'
import PcServerApi from '@/api/maintain/pc-server'

const defaultQuery = {
  page: 1,
  pageSize: 10,
  sorts: []
}

@Component({
  name: 'teamRequestHistoryRecordDataList',
  components: { ExpendSearchPanel, Gateway, Log, LogConfig }
})
export default class ApiGatewayDataList extends Vue {
  // 当前状态
  loading = false

  self: any = this;

  apiInstance = [] as AppInstance[]
  curTab = 'gateway'
  /** ip地址 */
  ip = ''
  /** 端口号 */
  port = ''
  /** 网关组名称 */
  apigatewayGroupName = ''

  /** 当前选中实例 */
  curInstance={} as AppInstance

  /// 查询数据
  gatewayQueryData: PagerQueryBean = _.cloneDeep({ ...defaultQuery, apigatewayGroupName: '', name: '' });
  logQueryData: PagerQueryBean = _.cloneDeep({ ...defaultQuery, sorts: ['requestTime desc'], requestUrl: '', methodType: '', startTime: '', endTime: '', duration: null, error: null, responseCode: null })
  logConfigQueryData: PagerQueryBean = _.cloneDeep({ ...defaultQuery, name: '', antMatcherUrl: '' })

  firstPcServerId = 0;

  /**
 * 是否开通企业用户
 */
  get opening () {
    const user = getterUserInfo()
    return user.operaExpirationTime && moment(user.operaExpirationTime).isAfter(moment.now())
  }

  @Watch('curTab')
  onCurTabChange () {
    this.doPullDataList()
  }

  created () {
    AppInstanceApi.listAppInstanceByImageType(2).then(response => {
      this.apiInstance = response.data || []
      if (!this.opening) {
        PcServerApi.getFirstPcServerId().then((res) => {
          this.firstPcServerId = res.data || 0
          this.apiInstance = this.apiInstance.filter((e) => e.pcServerId === this.firstPcServerId)
        })
      }

      if (getterGatewayInstance()?.id) {
        this.curInstance = getterGatewayInstance()
        this.changeInstance(this.curInstance.id)
      }
    })
  }

  activated () {
    AppInstanceApi.listAppInstanceByImageType(2).then(response => {
      this.apiInstance = response.data || []
    })
    if (this.ip && this.apigatewayGroupName) {
      this.doPullDataList()
    }
  }

  checkNumberWithSeconds () {
    if (!this.logQueryData.duration) this.logQueryData.duration = null
    else if (!parseFloat(this.logQueryData.duration)) this.logQueryData.duration = '0s'
    else this.logQueryData.duration = parseFloat(this.logQueryData.duration) + 's'
  }

  checkNumber () {
    if (!this.logQueryData.responseCode) this.logQueryData.responseCode = null
    else if (!parseFloat(this.logQueryData.responseCode)) this.logQueryData.responseCode = null
    else this.logQueryData.responseCode = parseFloat(this.logQueryData.responseCode)
  }

  selectError (val: string) {
    if (!val) this.logQueryData.error = null
    else if (val === 'false') this.logQueryData.error = false
    else this.logQueryData.error = true
  }

  /** 初始化网关 */
  initGateway () {
    const userInfo = getterUserInfo()
    apiGateway.initGateWay(userInfo.activedTeamId, this.curInstance.id!, this.ip, this.port).then(async (res) => {
      if (res.code === 0) {
        this.$Message.success('网关初始化成功')
        await apiGateway.finishInit(this.curInstance.id!)
        AppInstanceApi.listAppInstanceByImageType(2).then(response => {
          this.apiInstance = response.data || []
          this.curInstance = this.apiInstance.find((e) => e.id === this.curInstance.id) || ({} as AppInstance)
          this.doPullDataList()
        })
      } else {
        this.$Message.error('网关初始化失败,' + res.message)
      }
    }).catch((err) => {
      this.$Message.error('网关初始化失败,' + err.message)
    })
  }

  changeInstance (id: any) {
    this.curInstance = this.apiInstance.find((item) => item.id === id) || ({} as AppInstance)
    // 缓存
    updateGatewayInstance(this.curInstance)
    this.ip = this.curInstance.pcServerIpAddress || ''
    /// 请求port
    AppInstanceConfigApi.query({ page: 1, pageSize: 10, sorts: [], appInstanaceId: this.curInstance.id, configType: BaseConfigType.PORT }).then((res) => {
      this.port = res.data?.items[0].configValue || ''
      /// 请求分组名称
      AppInstanceConfigApi.query({ page: 1, pageSize: 10, sorts: [], appInstanaceId: this.curInstance.id, configKey: 'GATEWAY_GROUP_NAME' }).then((res) => {
        this.apigatewayGroupName = res.data?.items[0]?.configValue || ''
        this.doPullDataList()
      })
    })
  }

  /** 刷新规则 */
  reloadRule () {
    apiGateway.reoloadRouteRule(this.ip, this.port, this.curInstance.id!).then((re) => {
      if (re.code === 0) {
        this.$Message.success('刷新成功')
      } else {
        this.$Message.error(re.message)
      }
    }).catch((err) => {
      this.$Message.error(err.message)
    })
  }

  /**
   * 收缩搜索条
   */
  shrinkSeachbar () {
    const newQuery = _.cloneDeep(defaultQuery)
    switch (this.curTab) {
      case 'gateway':
        if (!_.isEqual(newQuery, this.gatewayQueryData)) {
          this.$nextTick(() => {
            this.gatewayQueryData = newQuery
            this.doPullDataList()
          })
        }
        break
      case 'log':
        if (!_.isEqual(newQuery, this.logQueryData)) {
          this.$nextTick(() => {
            this.logQueryData = newQuery
            this.doPullDataList()
          })
        }
        break
      case 'logConfig':
        if (!_.isEqual(newQuery, this.logConfigQueryData)) {
          this.$nextTick(() => {
            this.logConfigQueryData = newQuery
            this.doPullDataList()
          })
        }
        break
    }
  }

  /// 开关关闭前提示
  handleBeforeChange (enable: boolean) {
    return new Promise((resolve) => {
      this.$Modal.confirm({
        title: '切换确认',
        content: enable
          ? '当前网关规则正在启用，关闭后，网关规则将会失效，是否确认关闭？'
          : '是否确认启用该网关规则？',
        onOk: () => {
          resolve('')
        }
      })
    })
  }

  /**
   * 页面数据拉取
   */
  doPullDataList () {
    if (!this.ip) this.$Notice.warning({ title: '警告', desc: '请先选择实例' })
    switch (this.curTab) {
      case 'gateway':
        (this.$refs.gateway as any).doPullDataList()
        break
      case 'log':
        (this.$refs.log as any).doPullDataList()
        break
      case 'logConfig':
        (this.$refs.logConfig as any).doPullDataList()
        break
    }
  }
}
